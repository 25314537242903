import { yupResolver } from '@hookform/resolvers/yup';
import * as Popover from '@radix-ui/react-popover';
import { themeGet } from '@styled-system/theme-get';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import posthog from 'posthog-js';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

import { Box } from '@/components/box';
import { Button } from '@/components/button';
import { TextInput } from '@/components/inputs';
import { Link } from '@/components/link';
import { StyledForm } from '@/components/signin/shared-components';
import { Text } from '@/components/text';
import { useNotifications } from '@/hooks/use-notifications';
import { fluentProvider } from '@/lib/api/providers/fluent';
import { NEXT_AUTH_STATUSES, USER_MESSAGES } from '@/lib/constants';
import { marketingPages } from '@/lib/constants/marketing-site';
import { media } from '@/utils/media';
import { trackUser } from '@/utils/tracking';

interface Props {
  session: Session;
  isOpen: boolean;
  setFeedbackIsOpen: (isOpen: boolean) => void;
  status?: (typeof NEXT_AUTH_STATUSES)[keyof typeof NEXT_AUTH_STATUSES];
}

export interface FeedbackFormInterface {
  email: string;
  feedback: string;
}

export const feedbackValidationSchema = yup.object().shape({
  feedback: yup.string().required('Enter a message'),
  email: yup.string().email('Invalid email address'),
});

const FeedbackPopover = ({ isOpen, session, status, setFeedbackIsOpen }: Props) => {
  const { addNotification } = useNotifications();
  const router = useRouter();
  const user = session?.user;
  // eslint-disable-next-line no-underscore-dangle
  const postHogUserId = posthog?.__loaded ? posthog?.get_distinct_id() : 'unknown';

  const isSignedIn = status === NEXT_AUTH_STATUSES.AUTHENTICATED;

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FeedbackFormInterface>({
    defaultValues: {},
    resolver: yupResolver(feedbackValidationSchema),
  });

  const handleFeedbackSubmit = async ({ feedback, email }: FeedbackFormInterface) => {
    try {
      await fluentProvider.sendFeedbackEmail({
        feedback,
        email,
        meta: {
          url: router.asPath,
          postHogUserId,
        },
      });

      addNotification({
        heading: 'Success!',
        description: 'Feedback sent',
        variant: 'success',
      });

      setFeedbackIsOpen(false);

      trackUser.event('Submit feedback');

      // Reset the form incase the user wants to forward another quote
      reset();
    } catch (error: any) {
      addNotification({
        heading: 'Error sending feedback',
        description: `There was an issue sending the feedback. ${USER_MESSAGES.TRY_AGAIN}`,
        variant: 'error',
      });
    }
  };

  return (
    <StyledPopoverContent sideOffset={10} forceMount asChild>
      <motion.div
        initial="hidden"
        exit="hidden"
        animate={isOpen ? 'open' : 'hidden'}
        variants={{
          open: { opacity: 1, scale: 1 },
          hidden: { opacity: 0, scale: 0.8 },
        }}
      >
        <StyledForm onSubmit={handleSubmit(handleFeedbackSubmit)} mt={{ _: !isSignedIn ? 'xl' : 'xxl', md: 0 }} p="md">
          <ol>
            <li>
              <Controller
                control={control}
                name="feedback"
                defaultValue=""
                render={({ field }) => {
                  return (
                    <TextInput
                      {...field}
                      label="Your feedback"
                      placeholder="Ideas on how to improve this page, feedback on our results or data inaccuracies."
                      errors={errors}
                      rows="5"
                      isTextArea
                      disabled={isSubmitting}
                    />
                  );
                }}
              />
            </li>
            <Box as="li" display={user?.email ? 'none' : 'block'}>
              <Controller
                control={control}
                name="email"
                defaultValue={user?.email || ''}
                render={({ field }) => {
                  return (
                    <TextInput {...field} label="Email address" errors={errors} isOptional disabled={isSubmitting} />
                  );
                }}
              />
            </Box>
            <FormActions>
              <Button
                type="button"
                disabled={isSubmitting}
                size="small"
                variant="secondaryLight"
                onClick={() => setFeedbackIsOpen(false)}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting} isLoading={isSubmitting} size="small">
                Send feedback
              </Button>
            </FormActions>
          </ol>
          <FooterText variant="sm" className="mb-0">
            If you have a technical issue, need support, or want to ask a question, please use our{' '}
            <Link href={marketingPages.contact.url}>contact form</Link>.
          </FooterText>
        </StyledForm>
      </motion.div>
    </StyledPopoverContent>
  );
};

const StyledPopoverContent = styled(Popover.Content)`
  background-color: ${themeGet('colors.grey.100')};
  border-radius: ${themeGet('radii.default')};
  box-shadow: ${themeGet('shadows.md')};
  color: ${themeGet('colors.grey.800')};
  transform-origin: var(--radix-popover-content-transform-origin);
  width: 100vw;

  ${media.md} {
    width: 400px;
    background-color: ${themeGet('colors.white')};
  }
`;

const FormActions = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${themeGet('space.base')};
`;

const FooterText = styled(Text)`
  a {
    color: ${themeGet('colors.lightBlue.600')};
  }
`;

export { FeedbackPopover };
