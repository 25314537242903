import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Box } from '@/components/box';
import { Button } from '@/components/button';
import { Flex } from '@/components/flex';
import { Heading } from '@/components/heading';
import { Link } from '@/components/link';
import { Text } from '@/components/text';
import { CENTER_CONTENT_MAX_WIDTH } from '@/lib/constants';
import { media } from '@/utils/media';

export const Wrapper = styled(Flex)`
  border: 12px solid ${themeGet('colors.lightBlue.50')};
  min-height: 100vh;
  justify-content: center;
  padding: ${themeGet('space.xxxl')} ${themeGet('space.base')} ${themeGet('space.base')} ${themeGet('space.base')};

  ${media.lg} {
    padding: ${themeGet('space.xxxl')} ${themeGet('space.lg')};
  }
`;

export const Inner = styled(Box)`
  width: 350px;

  ${({ isWide }) =>
    isWide &&
    css`
      width: 100%;
      max-width: ${CENTER_CONTENT_MAX_WIDTH};
    `}
`;

export const Logo = styled(Box)`
  margin-bottom: ${themeGet('space.lg')};
  width: 150px;
`;

export const StyledHeading = styled(Heading).attrs({ variant: 'h2' })`
  margin-bottom: ${themeGet('space.sm')};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;

export const BreadcrumbsWrapper = styled(Box)`
  position: absolute;
  top: ${themeGet('space.base')};
  left: ${themeGet('space.sm')};
`;

export const TopHintMessage = styled(Text)`
  position: absolute;
  top: ${themeGet('space.lg')};
  right: ${themeGet('space.lg')};

  > span {
    display: none;

    ${media.sm} {
      display: inline;
    }
  }

  button {
    color: ${themeGet('colors.lightBlue.500')};
    margin-left: ${themeGet('space.base')};
  }
`;

export const IconWrapper = styled(Box)`
  border-radius: ${themeGet('radii.default')};
  width: ${themeGet('space.lg')};
  position: absolute;
  left: ${themeGet('space.xs')};
  top: 50%;
  transform: translateY(-50%);
`;

export const DividerText = styled(Text)`
  margin-top: ${themeGet('space.lg')};
  margin-bottom: ${themeGet('space.lg')};
  text-align: center;
  position: relative;
  overflow: hidden;

  > span {
    z-index: ${themeGet('zIndices.2')};
    position: relative;
  }

  span:before,
  span:after {
    content: '';
    position: absolute;
    height: 1px;
    background-color: ${themeGet('colors.grey.300')};
    top: 50%;
    transform: translateY(1px);
    width: 600px;
  }

  span:before {
    right: 100%;
    margin-right: ${themeGet('space.base')};
  }

  span:after {
    left: 100%;
    margin-left: ${themeGet('space.base')};
  }
`;

export const StyledPre = styled.pre`
  display: inline-block;
  margin: 0;
`;

export const StyledForm = styled(Box).attrs({ as: 'form' })`
  ol > li:not(:last-child) {
    margin-bottom: ${themeGet('space.5')};
  }
`;

export const StyledLink = styled(Link)`
  color: ${themeGet('colors.grey.500')};
`;
