/**
 * We're using the svg's directly in the component to avoid the time delay
 * when injecting the svg into the DOM using react-svg as this is
 * important to have a super fast loading time.
 */
import { tv } from '@/utils/styles';

import { LogoColored } from './colored';
import { LogoMono } from './mono';

interface LogoProps {
  variant?: 'light' | 'dark' | 'colored';
  className?: string;
}

const Logo = ({ variant = 'light', className, ...props }: LogoProps) => {
  const { base, icon } = styles();
  return (
    <div className={base({ className })} {...props}>
      {(variant === 'light' || variant === 'dark') && <LogoMono className={icon()} variant={variant} />}
      {variant === 'colored' && <LogoColored className={icon()} />}
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'relative',
    icon: 'h-full w-[140px] lg:w-[152px]',
  },
});

export { Logo };
