/* Custom standard media query breakpoints
 * for use with Styled Components
 *
 * Usage:
 *
 * ${media.lg {
    height: 100%;
  };
 *
 */

import { breakpoints } from '@/theme/constants/breakpoints';

const customMediaQuery = (maxWidth: string, widthDirection = 'min-width'): string =>
  `@media (${widthDirection}: ${maxWidth})`;

const media = {
  custom: customMediaQuery,
  xs: customMediaQuery(breakpoints.sm, 'max-width'),
  sm: customMediaQuery(breakpoints.sm),
  md: customMediaQuery(breakpoints.md),
  untilMd: customMediaQuery('767px', 'max-width'), // 1px less than breakpoints.md to work correctly
  lg: customMediaQuery(breakpoints.lg),
  xl: customMediaQuery(breakpoints.xl),
  xxl: customMediaQuery(breakpoints.xxl),
  xxxl: customMediaQuery(breakpoints.xxxl),
};

export { media };
