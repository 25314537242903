import { isEmpty } from 'lodash';

import { ButtonGroup } from '@/components/button-group';
import { Container } from '@/components/container';
import { Heading } from '@/components/heading';
import { RichText } from '@/components/rich-text';
import { Text } from '@/components/text';
import { BlockProps, KeyedObjInterface } from '@/types/block-types';
import { cn, tv } from '@/utils/styles';

export const blockAlignmentOptions: KeyedObjInterface = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const Block = ({
  blockAlignment = 'left',
  body = null,
  buttons,
  children = null,
  contentColumns = {
    left: 'w-full md:w-9/12',
    center: 'w-full md:w-9/12',
    right: 'w-full md:w-9/12',
  },
  hasButtonsAtTheBottom = false,
  heading,
  headingVariant = 'h1',
  headingElement = 'h2',
  id,
  preHeading,
  preHeadingVariant = 'uppercase',
  hasSlimContainer,
  hasSkinnyContainer,
  noTopPadding,
  size,
  className,
}: BlockProps) => {
  const { base, headingStyles, preHeadingStyles, richText, inner, outer, buttonGroup, buttonGroupWrapper } =
    blockStyles({
      hasSlimContainer,
      hasSkinnyContainer,
      blockAlignment,
      noTopPadding,
      size,
    });

  return (
    <section id={id} className={base({ className })}>
      <Container>
        <div className={outer()}>
          {(heading || body || !isEmpty(buttons)) && (
            <header className={contentColumns[blockAlignment]}>
              <div className={inner()}>
                {preHeading && (
                  <Text variant={preHeadingVariant} className={preHeadingStyles()}>
                    {preHeading}
                  </Text>
                )}
                {heading && (
                  <Heading variant={headingVariant} className={headingStyles()} as={headingElement}>
                    {heading}
                  </Heading>
                )}
                {body && <RichText className={richText()}>{body}</RichText>}

                {!isEmpty(buttons) && !hasButtonsAtTheBottom && (
                  <ButtonGroup buttons={buttons} className={buttonGroup({ class: 'mt-8 lg:mt-12' })} size={size} />
                )}
              </div>
            </header>
          )}
          {children && <div className="w-full">{children}</div>}

          {!isEmpty(buttons) && hasButtonsAtTheBottom && (
            <div className={cn(contentColumns[blockAlignment], 'mt-8')}>
              <div className={buttonGroupWrapper()}>
                <ButtonGroup buttons={buttons} className={buttonGroup()} size={size} />
              </div>
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export const blockStyles = tv({
  slots: {
    base: 'block-wrapper mx-auto w-full py-16 xl:py-24',
    headingStyles: 'mb-6',
    preHeadingStyles: 'block-pre-heading mb-3 text-grey-500',
    richText: 'block-rich-text max-w-[var(--narrow-center-content-max-width)]',
    inner: 'mb-8 lg:mb-12',
    outer: 'flex flex-wrap',
    buttonGroup: null,
    buttonGroupWrapper: null,
  },
  variants: {
    hasSlimContainer: {
      true: 'max-w-5xl',
    },
    hasSkinnyContainer: {
      true: 'max-w-[600px]',
    },
    blockAlignment: {
      left: {
        richText: 'mx-0',
        inner: 'text-left',
        outer: 'justify-start',
        buttonGroupWrapper: 'text-left',
        buttonGroup: 'justify-start',
      },
      center: {
        richText: 'mx-auto',
        inner: 'text-center',
        outer: 'justify-center',
        buttonGroupWrapper: 'text-center',
        buttonGroup: 'justify-center',
      },
      right: {
        richText: 'mx-0',
        inner: 'text-right',
        outer: 'justify-end',
        buttonGroupWrapper: 'text-right',
        buttonGroup: 'justify-end',
      },
    },
    noTopPadding: {
      true: {
        base: 'pt-0 sm:pt-0 md:pt-0 lg:pt-0 xl:pt-0 2xl:pt-0 3xl:pt-0',
      },
    },
    size: {
      lg: {
        richText: 'max-w-3xl text-baseLg leading-7 lg:text-md lg:leading-8',
        headingStyles: 'mb-4 text-lg font-semibold leading-tight text-blue-800 md:text-xl lg:text-xl',
      },
    },
  },
});

export { Block };
