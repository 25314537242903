import { atom, selector } from 'recoil';

import { RouteModel } from '@/lib/models/route/types';
import { advancedSearchFormOpenState } from '@/state/search';
import { LocationInterface, RouteStatsInterface } from '@/types';

// Routes List
export const routesState = atom<RouteModel[]>({
  key: 'routesState',
  default: [],
});

export const selectedRouteState = atom<RouteModel | null>({
  key: 'selectedRouteState',
  default: null,
});

// Routes Count
export const routesCountState = selector<number>({
  key: 'routesCountState',
  get: ({ get }) => {
    const routesArr = get(routesState);
    return routesArr.length;
  },
});

interface ViewIdsInterface {
  routeId?: string;
  blockId?: string;
  optionId?: string;
}

// Keep track of ids from each view
export const viewIdsState = atom<ViewIdsInterface>({
  key: 'viewIdsState',
  default: {
    routeId: '',
    blockId: '',
    optionId: '',
  },
});

export const filtersPanelOpenState = atom<boolean>({
  key: 'filtersPanelOpenState',
  default: false,
});

export const routesStatsState = atom<RouteStatsInterface | null>({
  key: 'routesStatsState',
  default: null,
});

export const routeSearchOriginState = atom<LocationInterface | undefined>({
  key: 'routeSearchOriginState',
  default: undefined,
});

export const routeSearchDestinationState = atom<LocationInterface | undefined>({
  key: 'routeSearchDestinationState',
  default: undefined,
});

export const globalRoutesReset = selector({
  key: 'globalRoutesReset',
  get: () => ({}),
  set: ({ reset, set }) => {
    reset(viewIdsState);
    reset(routesState);
    reset(routesStatsState);
    set(advancedSearchFormOpenState, false);
  },
});
