/**
 * Generic container component to center content and set max-width
 */

import React from 'react';

import { tv } from '@/utils/styles';

interface Props {
  className?: string;
  children: React.ReactNode;
  variant?: 'slim';
}

const Container = ({ className, children, variant, ...props }: Props) => {
  const { base } = styles({ variant });
  return (
    <div className={base({ className })} {...props}>
      {children}
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'mx-auto w-full max-w-[--container-max-width] px-5 md:px-6 lg:px-8',
  },
  variants: {
    variant: {
      slim: 'max-w-[--container-max-width-slim]',
    },
  },
});

export { Container };
